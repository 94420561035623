<template>
  <div id="form">
    <div class="container">
      <form @submit.prevent="submitForm" id="form-1" method="post">
          <legend>Tahmini Beden Ölçüsü Hesaplama</legend>
          <div class="row mb-3">
            <label for="field1" class="col-sm-2 col-form-label">Boyunuz (cm):</label>
            <div class="col-sm-6">
              <input type="text" id="field1" name="size" class="form-control" required v-model="size" />
              <span v-if="!isValidSize" class="text-danger">Lütfen geçerli bir boy girin.</span>
            </div>
            <div class="col-sm-4">
              <span id="kontrol">(140 - 190 cm aralığı)</span>
            </div>
          </div>

          <div class="row mb-3">
            <label for="field2" class="col-sm-2 col-form-label">Kilonuz (kg):</label>
            <div class="col-sm-6">
              <input type="text" id="field2" name="kilo" class="form-control" required v-model="weight" />
              <span v-if="!isValidWeight" class="text-danger">Lütfen geçerli bir kilo girin.</span>
            </div>
            <div class="col-sm-4">
              <span id="kontrol">(30 - 130 kg aralığı)</span>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-sm-12">
              <button type="submit" class="btn btn-primary me-2" :disabled="!isValidSize || !isValidWeight">Hesapla</button>
              <button type="reset" class="btn btn-secondary" @click="resetForm">Temizle</button>
            </div>
          </div>

          <div class="row mt-3 result" v-if="showResult">
            <div class="col-sm-12">
              <hr>
              <p><b>Beden Hesaplama Sonucu</b></p> Bedeniniz
              <span style="font-size:20px;"><b>{{ result }}</b></span>
              olarak hesaplanmıştır.
            </div>
          </div>
      </form>
    </div>
    <button @click="logout()" id="logout-btn">Çıkış Yap</button>    
  </div>
</template>

<script>
export default {
  data(){
    return{
      size: '',
      weight: '',
      showResult: false,
      result: '',
    }
  },
  methods: {
    logout(){
      localStorage.clear()
      location.reload()
    },
    submitForm() {
      var boyArtisi = (this.size - 140) * 0.4;
      var kiloArtisi = (this.weight - 40) * 0.4;
      var beden = 41 + kiloArtisi - boyArtisi;
      if(Number.isInteger(beden)) {
        this.result = beden
      } else {
        this.result = beden.toFixed(1)
      }
      this.showResult = true;
    },
    resetForm() {
      this.size = '';
      this.weight = '';
      this.showResult = false;
      this.result = '';
    },
  },
  mounted() {

  },
  computed: {
    isValidSize() {
      return this.size >= 140 && this.size <= 190;
    },
    isValidWeight() {
      return this.weight >= 30 && this.weight <= 130;
    }
  }
}
</script>

<style scoped>
#logout-btn {
  position: fixed;
  top: 10px;
  right: 10px;
}
#logout-btn {
    background-color: #4CAF50;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
#logout-btn:hover {
    background-color: #3e8e41;
  }

#form {
  margin-top: 50px;
}
.container {
  max-width: 800px; /* Formun maksimum genişliği */
  margin: 0 auto; /* Sayfada yatay olarak ortalamak için */
}

#kontrol {
  display: flex;
  align-items: center;
}
</style>